<template>
    <div>
        <MobileMenu
            v-if="menu"
            @close="menu=false"
        />
        <header class="header">
            <div class="header__content">
                <div>
                    <a
                        v-if="$route.name === 'Landing'"
                        href="/"
                        class="logo"
                    ></a>
                    <router-link
                        v-else
                        :to="{ name: 'Landing' }"
                        class="logo"
                    ></router-link>
                </div>
                <div class="header__mobile">
                    <span class="header__burger" @click="menu=true"><span></span></span>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import './header.scss';
import contacts from '@/mixins/contacts';

export default {
    name: 'Header',
    mixins: [
        contacts
    ],
    data() {
        return {
            menu: false
        };
    },
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
    components: {
        MobileMenu: () => import('./MobileMenu'),
    }
};
</script>
