<template>
    <div class="common-t">
        <div class="common-t__wrapper">
            <Header class="common-t__header"/>
            <p
                v-if="$slots.title"
                class="common-t__title"
            >
                <slot name="title"/>
            </p>
            <p
                v-if="$slots.desc"
                class="common-t__desc"
            >
                <slot name="desc"/>
            </p>
            <slot name="top"/>
            <div class="common-t__content">
                <slot/>
            </div>
        </div>
        <div class="common-t__footer">
            <Footer/>
        </div>
    </div>
</template>

<script>
import './common-t.scss'
import Header from '@/components/common/header/Header'
import Footer from '@/components/common/footer/Footer'

export default {
    name: 'CommonTemplate',
    components: {
        Header,
        Footer,
    }
}
</script>