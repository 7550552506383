<template>
    <div
        class="step-t"
        :class="{
            'step-t__hide-burger': hideBurger
        }"
    >
        <MobileMenu
            v-if="menu"
            @close="menu=false"
        />
        <div class="step-t__wrapper">
            <div class="step-t__header-wrapper">
                <div class="step-t__header">
                    <h1
                        v-if="$slots.title || $slots.headerTitle"
                        class="step-t__title"
                        :class="titleClass"
                    >
                        <slot
                            v-if="$slots.headerTitle"
                            name="headerTitle"
                        />
                        <slot
                            v-else
                            name="title"
                        />
                    </h1>
                    <span class="step-t__burger" @click="openMenu"><span></span></span>
                </div>
            </div>
            <div class="step-t__content-wrapper">
                <div
                    v-if="$slots.top"
                    class="step-t__top"
                    :class="topClass"
                >
                    <slot name="top"/>
                </div>
                <div class="step-t__content">
                    <slot/>
                </div>
            </div>
        </div>
        <div class="step-t__footer">
            <Footer/>
        </div>
    </div>
</template>

<script>
import './step-t.scss'

export default {
    name: 'StepTemplate',
    props: {
        topClass: String,
        titleClass: String,
        isLong: Boolean,
        hideBurger: Boolean,
    },
    data() {
        return {
            menu: false
        };
    },
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
    components: {
        Footer: () => import('@/components/common/footer/Footer'),
        MobileMenu: () => import('@/components/common/header/MobileMenu'),
    }
}
</script>