import checkUserGuard from '@/guards/checkUser'

import Store from '@/store'

import Vue from 'vue'
import VueRouter from 'vue-router'

import landing from '@/applications/landing/router'
import loanApplication from '@/applications/loan-application/router'
import personalArea from '@/applications/personal-area/router'
import unsubscribe from '@/applications/unsubscribe/router'
import login from '@/applications/login/router'
import final from '@/applications/final/router'
import notFound from '@/applications/not-found/router'

import App from '@/App'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        beforeEnter: checkUserGuard,
        component: App,
        children: [
            ...landing,
            ...loanApplication,
            ...personalArea,
            ...unsubscribe,
            ...login,
            ...final,
            ...notFound,
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});


router.beforeEach((to, from, next) => {
    Store.commit('application/load', true)
    next()
})

router.afterEach(() => {
    Store.commit('application/load', false)
})

export default router;
